<template>
<!-- 体育协会 弹框 -->
	<div class="asspopout">
		<div class="container darkBack">
			<!-- 右上角关闭按钮 -->
			<!-- 左侧协会介绍 -->
			<div class="associationLeft">
				<!-- 上方协会信息 -->
				<div class="association">
					<div class="associationInfo">
						<img class="badge" :src="associationInfo.logo" >
						<div>
							<p class="whiteFont font22">{{associationInfo.title}}</p>
							<p class="gray font14">协会地址：{{associationInfo.address}}</p>
						</div>
					</div>
					<div class="borderBack vip">
						<p class="font12 lightBlueFont">会员总人数</p>
						<p class="font20 blueFont">
							<countTo :startVal='0' :endVal='membersInfo.length' :duration='3000'></countTo>
						</p>
					</div>
				</div>
				<!-- 协会项目简介 -->
				<div class="introduce" v-html="associationInfo.content"></div>
				<!-- <div class="introduce">“协会”的口号是：{{associationInfo.slogan}}</div>
				<div class="introduce">
					<div>协会”的业务范围：</div>
					<div v-for="(business, index) in associationInfo.business.list" :key="index">{{business}}</div>
				</div> -->
				
				<!-- 协会相册 -->
				<div class="photoAlbum">
					<img :src="item" v-for="(item,index) in associationInfo.image_arr" :key='index'>
				</div>
			</div>
			<!-- 右侧协会会员 -->
			<div class="members">
				<table>
					<thead>
						<td>协会职位</td>
						<td>协会联系人</td>
						<td>联系人电话</td>
					</thead>
					<tr v-for="(item,index) in membersInfo" :key="index">
						<td>{{item.post}}</td>
						<td>{{item.realname}}</td>
						<td>{{item.phone}}</td>
					</tr>
				</table>
			</div>
			<div class="remove" @click="skipasspopout">
				<img src="../../../assets/images/remove.png" alt="">
			</div>
		</div>
	</div>
</template>

<script>
	// 引入数字滚动组件
import countTo from 'vue-count-to';
import {getAjax, timestamp} from "../../../assets/js/websocket";

  export default {
    components: { countTo },
		data() {
			return{
				associationInfo:{
					// badge:require('../../../assets/images/badge.png'),
					// name: "城南江北马拉松协会",
					// site: "城南新村江北公寓15号",
					// vipNum: 69,
					// introduce:"“协会”的宗旨是：遵守宪法、法律、法规和国家政遵守社会道德风尚，服务全民健身，倡导跑步运动，增强人民体质。",
					// slogan:"运动改变生活！",
					// business:{
					// 	list:["一、赛事承办：举办日常的公园跑、校园跑等约跑活动，小型企事业单位团建活动（100人-500人），多站城市路跑比赛，马拉松举办、越野赛、定向赛等；", "二、跑步旅游：国内外马拉松报名、比赛地酒店预定、往返交通、旅游服务等；", "三、认证培训：提供跑步训练课程、企事业单位健身运动及应急救护讲座、专业健身教练培训认证、美国心脏协会急救认证。"],
					// },
					// image:{
					// 	img:[
					// 		require('../../../assets/images/association1.png'),
					// 		require('../../../assets/images/association2.png')
					// 	]
					// },
					
				},
				membersInfo:[]
			}
		},
		methods:{
			skipasspopout(){
				this.$store.state.asspopout = false 
			}
		},
		mounted() {
			
		},
		created() {
			let that = this;
			var data = {
                token: window.getToken(),
                app_id: window.appId,
                time: timestamp(),
            }
            getAjax({
                url: "/map/screen/getSportsAssociationInfo?id="+window.associationId,
                method:"GET",
                data:data,
                success:function(res){
                    // console.log(res)
					that.associationInfo = res.data;
					that.membersInfo = res.data.contact_list;
					// console.log(that.membersInfo)
                },
            })
		}
	}
</script>

<style scoped>
	.asspopout{
		width:19.20rem;
		height:10.80rem;
		background:#000000BF;
		position: relative;
		border: 0.01rem solid #000000B3;
		z-index: 1000;
	}
	.gray{color: #AAAAAA;}
	.container{
		width: 12.11rem;
		height: 5.76rem;
		/* position: absolute;
		top: 10%; */
		padding: 0.2rem;
		display: flex;
		margin:auto;
		margin-top:1.7rem;
		border:0.01rem solid #6076ad;
	}
	.close{
		width: 0.34rem;
		height: 0.34rem;
		line-height: 0.28rem;
		text-align: center;
		border-radius: 50%;
		background-color: black;
		color: #9F9F9F;
		font-size: 0.3rem;
		position: absolute;
		top: -0.17rem;
		right: -0.17rem;
		cursor:pointer;
		z-index: 10;
		border: 0.01rem solid #9F9F9F;
	}
	.associationLeft{
		width: 50%;
		border-right: 0.01rem solid #24345A;
		padding-right: 0.2rem;
	}
	.association{
		width: 100%;
		display: flex;
		justify-content: space-between;
		align-items: center;
	}
	.associationInfo{
		display: flex;
		align-items: center;
	}
	.badge{
		width: 0.88rem;
		height: 0.76rem;
		margin-right: 0.1rem;
	}
	.vip{
		width: 1.38rem;
		height: 0.56rem;
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
	}
	.introduce{
		color: #7EA7E2;
		font-size: 0.14rem;
		max-height: 2.6rem;
		line-height: 0.24rem;
		overflow-y: auto;
		margin-top: 0.2rem;
	}
	 /*滚动条样式*/
	.introduce::-webkit-scrollbar {
		width: 0.05rem;    
		height: 0rem;
	}


	.introduce::-webkit-scrollbar-thumb {
		border-radius: 0.1rem;
		box-shadow: inset 0 0 0.05rem #7EA7E2;
		background: #7EA7E2;
	}
	.introduce::-webkit-scrollbar-track {
		box-shadow: inset 0 0 0.05rem #7EA7E2;
		border-radius: 0.1rem;
	}
	.photoAlbum{
		display: flex;
		justify-content: flex-start;
		margin-top: 0.3rem;
	}
	.photoAlbum > img{
		width: 2.57rem;
		height: 1.44rem;
		margin-right: 0.34rem;
	}
	.members{
		overflow-y:auto;
	}
	 /*滚动条样式*/
	.members::-webkit-scrollbar {
		width: 0.05rem;    
		height: 0rem;
	}


	.members::-webkit-scrollbar-thumb {
		border-radius: 0.1rem;
		box-shadow: inset 0 0 0.05rem #7EA7E2;
		background: #7EA7E2;
	}
	.members::-webkit-scrollbar-track {
		box-shadow: inset 0 0 0.05rem #7EA7E2;
		border-radius: 0.1rem;
	}
	table{
		text-align: center;
		color: #7EA7E2;
		width: 4.5rem;
		font-size: 0.14rem;
		margin-left: 0.7rem;
	}
	.remove{
		width:0.34rem;
		height:0.34rem;
		position: absolute;
		top:1.54rem;
		right:3.4rem;
	}
	.remove>img{
		width:100%;
		height:100%;
	}
</style>